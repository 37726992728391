import { useCallback } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import Head from 'next/head';
import { getSession, SessionProvider } from 'next-auth/react';

import config from '@/config';
import { AuthProvider } from '@/context/AuthContext';
import { useApollo } from '@/graphql/apollo';
import { CustomSession } from '@/server/models/auth';
import theme from '@/theme';
import configure from '@/lib/configure/sentry';
import ph from '@/lib/configure/posthog';
import { TrackingProvider } from '@/context/TrackingContext';
import { FeatureFlagProvider } from '@/context/FeatureFlagContext';

configure(config.sentry.dsn, config.appEnv);

export default function App({
  Component,
  pageProps: { session: sessionProp, ...pageProps },
}): JSX.Element {
  const getAuthToken = useCallback(async () => {
    const session = (await getSession()) as CustomSession;
    return session?.idToken || null;
  }, []);

  const apolloClient = useApollo(pageProps.initialApolloState, getAuthToken);

  return (
    <ph.PostHogProvider>
      <FeatureFlagProvider>
        <Sentry.ErrorBoundary>
          <TrackingProvider>
            <ApolloProvider client={apolloClient}>
              <SessionProvider session={sessionProp}>
                <AuthProvider clearCache={() => apolloClient.clearStore()}>
                  <ChakraProvider resetCSS theme={theme}>
                    <>
                      <ColorModeScript
                        initialColorMode={theme.config.initialColorMode}
                      />
                      <Head>
                        <title>{config.title}</title>
                        <meta
                          content='width=device-width, initial-scale=1'
                          name='viewport'
                        />
                        <meta content='ie=edge' httpEquiv='x-ua-compatible' />
                      </Head>
                      <AnimatePresence>
                        <Component {...pageProps} />
                      </AnimatePresence>
                    </>
                  </ChakraProvider>
                </AuthProvider>
              </SessionProvider>
            </ApolloProvider>
          </TrackingProvider>
        </Sentry.ErrorBoundary>
      </FeatureFlagProvider>
    </ph.PostHogProvider>
  );
}
