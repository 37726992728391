const colors = {
  primary: {
    '50': '#E5FFF6',
    '100': '#B8FFE7',
    '200': '#8AFFD7',
    '300': '#5CFFC8',
    '400': '#2EFFB8',
    '500': '#00FFA8',
    '600': '#23d48d',
    '700': '#009965',
    '800': '#006643',
    '900': '#003322',
  },
  racingGreen: {
    '50': '#ECF9F9',
    '100': '#C9EDED',
    '200': '#A7E2E2',
    '300': '#85D6D6',
    '400': '#62CBCB',
    '500': '#40BFBF',
    '600': '#339999',
    '700': '#267373',
    '800': '#274342',
    '900': '#0D2626',
  },
  brand: {
    primary: '#23d48d',
    secondary: '#0D2626',
    secondaryOffset: '#08444c',
    backgroundDarkPrimary: '#0D2626',
    backgroundDarkSecondary: '#0D2626',
    backgroundLightPrimary: 'white',
    backgroundLightSecondary: '#F2F2F2',
    borderDark: 'rgba(255, 255, 255, 0.16)',
    borderLight: '#E2E8F0',
    textDark: '#101828',
    textLight: 'white',
  },
};

export default colors;
