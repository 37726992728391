import { Debug } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const configure = (dsn: string, env: string) => {
  Sentry.init({
    attachStacktrace: true,
    dsn,
    environment: env,
    tracesSampleRate: 1.0,

    integrations: [
      new Integrations.BrowserTracing(),
      ...(env === 'production'
        ? []
        : [
            new Debug({
              // Trigger DevTools debugger instead of using console.log
              debugger: false,
            }),
          ]),
    ],
  });
};

export default configure;
