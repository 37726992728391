import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  // define the part you're going to style
  textDecoration: 'underline',
});

const button = defineStyle({
  width: { base: '100%', md: 'auto' },
  fontWeight: 500,
  borderRadius: 'sm',
  p: 'sm',
  textDecoration: 'none',
  _hover: {
    textDecoration: 'none',
  },
});

const navButton = defineStyle({
  width: '100%',
  fontWeight: 500,
  borderRadius: 'sm',
  p: 'sm',
  textDecoration: 'none',
  _hover: {
    textDecoration: 'none',
  },
});

const linkTheme = defineStyleConfig({
  baseStyle,
  variants: { button, navButton },
});

export default linkTheme;
