import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    paddingInlineStart: { base: 'sm', md: 'md' },
    borderRadius: 'sm',
  },
});

const filled = definePartsStyle({
  field: {
    ...baseStyle.field,
    borderWidth: '1px',
    _focus: {
      borderColor: 'primary.600',
    },
  },
});

const inputTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    variant: 'filled',
    colorScheme: 'primary',
  },
  variants: { filled },
});

export default inputTheme;
