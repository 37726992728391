/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-console */

import {
  from,
  split,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { GetServerSidePropsContext } from 'next';
import { getServerSession } from 'next-auth';
import { getSession } from 'next-auth/react';
import { useMemo } from 'react';

import { authOptions } from '@/pages/api/auth/[...nextauth]';
import { CustomSession } from '@/server/models/auth';
import log from '@/services/log';

let apolloClient: ApolloClient<any>;

type GetAuthToken = () => Promise<string | null>;

export type ApolloClientContext = GetServerSidePropsContext;

function createApolloClient(
  ctx?: ApolloClientContext,
  getAuthToken?: GetAuthToken
) {
  const coreApiLink = new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_CORE_API}`,
  });
  const coreApiAuthLink = setContext(async (_, { headers }) => {
    let authToken: string;

    if (typeof window === 'undefined') {
      const session = (await getServerSession(
        ctx.req,
        ctx.res,
        authOptions
      )) as CustomSession;
      authToken = session?.idToken;
    } else if (ctx && ctx.req) {
      const session = (await getSession({ req: ctx.req })) as CustomSession;
      authToken = session?.idToken;
    } else if (getAuthToken) {
      authToken = await getAuthToken();
    } else {
      return;
    }

    let selectedEntityId = '';
    let selectedEntityType = '';
    if (typeof window !== 'undefined') {
      selectedEntityId = localStorage?.getItem('selectedEntityId') || '';
      selectedEntityType = localStorage?.getItem('selectedEntityType') || '';
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${authToken}`,
        'selected-entity-id': selectedEntityId,
        'selected-entity-type': selectedEntityType,
      },
    };
  });

  const storyblokLink = new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_STORYBLOK_URL}`,
    // other link options...
  });
  const storyblokAuthLink = setContext(async (_, { headers }) => {
    const token = `${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}`;
    return {
      headers: {
        ...headers,
        token: token || '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(async ({ message }) => {
        log.error(`[GraphQL error]: Message: ${message}`);
      });
    if (networkError) log.error(`[Network error]: ${networkError}`);
  });

  const uploadLink = createUploadLink({
    uri: `${process.env.NEXT_PUBLIC_CORE_API}`,
  });

  const storyblokLinks = from([storyblokAuthLink, errorLink, storyblokLink]);
  const coreApiLinks = from([
    coreApiAuthLink,
    errorLink,
    uploadLink, // should be before coreApiLink
    coreApiLink,
  ]);

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: split(
      (operation) => operation.getContext().apiName === 'storyblok',
      storyblokLinks, // <= apollo will send to this if apiName is "storyblok"
      coreApiLinks // <= otherwise will send to this
    ),
    cache: new InMemoryCache({
      typePolicies: {
        TransactionRecord: {
          fields: {
            transactionEvents: {
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(
  ctx?: ApolloClientContext, // passed only during SSR
  initialState: any = null,
  getAuthToken: GetAuthToken = undefined
) {
  const ac = apolloClient ?? createApolloClient(ctx, getAuthToken);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = ac.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    ac.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return ac;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = ac;
  }

  return ac;
}

export const getApolloClient = initializeApollo;

export function useApollo(initialState: any, getAuthToken?: GetAuthToken) {
  const store = useMemo(
    () => initializeApollo(null, initialState, getAuthToken),
    [initialState, getAuthToken]
  );
  return store;
}
