import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => ({
  // define the part you're going to style
  dialog: {
    bg: mode(
      'brand.backgroundLightSecondary',
      'brand.backgroundDarkSecondary'
    )(props),
  },
}));

const modalTheme = defineMultiStyleConfig({
  baseStyle,
});

export default modalTheme;
