import { Styles, mode } from '@chakra-ui/theme-tools';

const styles: Styles = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  global: (props) => ({
    '*': {
      boxSizing: 'border-box',
    },
    html: {
      fontFamily: 'body',
      fontSize: 'base',
      height: '100%',
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: mode('white', '#0D2626')(props),
    },

    body: {
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      margin: '0px',
      bg: mode('white', '#0D2626')(props),
    },

    '*, *::before, &::after': {
      wordWrap: 'break-word',
    },

    '#__next': {
      height: '100%',
      overflowY: 'auto',
    },

    // Utils
    '.disable-scroll': {
      overflowY: 'hidden',
    },

    // Custom scrollbar styles
    '::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent', // Transparent background
      border: '1px solid',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      borderRadius: 'sm',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  }),
};

export default styles;
