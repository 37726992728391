import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    paddingInlineStart: { base: 'sm', md: 'md' },
    borderRadius: 'sm',
    minWidth: { base: '120px', md: '140px', lg: '160px' },
  },
});

const filled = definePartsStyle({
  field: {
    ...baseStyle.field,
    borderWidth: '1px',
  },
});

const selectTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    variant: 'filled',
  },
  variants: { filled },
});

export default selectTheme;
