interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const firebaseConfig: Partial<IFirebaseConfig> | undefined =
  process.env.NEXT_PUBLIC_FIREBASE_CONFIG &&
  JSON.parse(
    Buffer.from(
      `${process.env.NEXT_PUBLIC_FIREBASE_CONFIG}`,
      'base64'
    ).toString()
  );

export default {
  env: process.env.NODE_ENV,
  appEnv: process.env.NEXT_PUBLIC_APP_ENV,
  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID,
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  socialMeta: {
    baseUrl: process.env.NEXT_PUBLIC_SOCIALMETA_BASEURL,
    title: process.env.NEXT_PUBLIC_SOCIALMETA_TITLE,
    description: process.env.NEXT_PUBLIC_SOCIALMETA_DESCRIPTION,
    keywords: process.env.NEXT_PUBLIC_SOCIALMETA_KEYWORDS,
    imageUrl: process.env.NEXT_PUBLIC_SOCIALMETA_IMAGEURL,
    companyName: process.env.NEXT_PUBLIC_SOCIALMETA_COMPANYNAME,
    twitterUser: process.env.NEXT_PUBLIC_SOCIALMETA_TWITTERUSER,
  },
  title: 'Impact Loop',
  tileColor: '#27e59b',
  firebaseConfig,
  // Server-side config (not exposed to the client)
  nextAuthUrl: process.env.NEXTAUTH_URL,
  nextAuthJwtSecret: process.env.NEXTAUTH_JWT_SECRET,
  amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  addressLookupApiKey: process.env.NEXT_PUBLIC_ADDRESS_LOOKUP_API_KEY,
  googleClientId: process.env.GOOGLE_CLIENT_ID,
  googleClientSecret: process.env.GOOGLE_CLIENT_SECRET,
  facebookAppId: process.env.FACEBOOK_APP_ID,
  facebookSecret: process.env.FACEBOOK_APP_SECRET,
};
