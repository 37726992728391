import breakpoints from './breakpoints';
import colors from './colors';
import radii from './radii';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import transitions from './transitions';
import typography from './typography';

const theme = {
  breakpoints,
  space,
  colors,
  ...typography,
  transitions,
  header: {
    height: 64,
  },
  radii,
  shadows,
  sizes,
};

export type Theme = typeof theme;

export default theme;
