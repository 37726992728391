/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { FC, useCallback } from 'react';
import ph from '@/lib/configure/posthog';

import { TrackingEvent, TrackingFunnel } from '@/enums/tracking';
import { EntityType, Membership, User } from '@/generated/core.graphql';

export type TrackingParams = {
  event: TrackingEvent.USER_REGISTERED;
  data: {
    funnel: TrackingFunnel.ONBOARDING;
    userId: number;
  };
};

type TrackingContext = {
  trackEvent: (config: TrackingParams) => Promise<void>;
  identifyUser: (user: User) => void;
  identifyGroup: (membership: Membership) => void;
  reset: () => void;
};

// eslint-disable-next-line no-redeclare
const TrackingContext = React.createContext<TrackingContext>({
  trackEvent: () => Promise.resolve(),
  identifyUser: () => undefined,
  identifyGroup: () => undefined,
  reset: () => undefined,
});

const TrackingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const trackEvent = useCallback(async (options: TrackingParams) => {
    const trackingParams = options as any;

    if (ph.isEnabled && trackingParams.data) {
      await ph.posthog.capture(options.event, (options as any).data);
    }

    // GTM
    if (window.dataLayer) {
      window.dataLayer.push({ event: options.event });
    }
  }, []);

  const identifyUser = useCallback((user: User) => {
    if (ph.isEnabled) {
      ph.posthog.identify(
        user.id.toString(), // Replace 'distinct_id' with your user's unique identifier
        { email: user.email, name: user.firstName } // optional: set additional person properties
      );
    }
  }, []);

  const identifyGroup = useCallback((membership: Membership) => {
    if (ph.isEnabled) {
      const entityType = EntityType[membership.entityType].toLowerCase();
      ph.posthog.group(entityType, `${entityType}::${membership.entityId}`, {
        name: membership.entity?.name,
      });
    }
  }, []);

  const reset = useCallback(() => {
    if (ph.isEnabled) {
      ph.posthog.reset();
    }
  }, []);

  return (
    <TrackingContext.Provider
      value={{
        trackEvent,
        identifyUser,
        identifyGroup,
        reset,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

function useTracking(): TrackingContext {
  const context = React.useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
}

export { TrackingProvider, useTracking };
