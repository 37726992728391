import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    borderRadius: '0',
  },
});

const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'primary',
  },
});

export default checkboxTheme;
