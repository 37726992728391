import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    overflow: 'hidden',
    borderRadius: 'sm',
    p: 'sm',
  },
  item: {
    bg: 'inherit',
    py: 'sm',
    borderRadius: 'sm',
  },
});

const menuTheme = defineMultiStyleConfig({
  baseStyle,
});

export default menuTheme;
