import * as React from 'react';
import { FC, useCallback } from 'react';
import ph from '@/lib/configure/posthog';

type FeatureFlagContext = {
  identifyForFeatures: (email: string) => void;
  isFeatureEnabled: (featureFlag: string) => boolean;
};

// eslint-disable-next-line no-redeclare
const FeatureFlagContext = React.createContext<FeatureFlagContext>({
  identifyForFeatures: () => undefined,
  isFeatureEnabled: () => false,
});

const FeatureFlagProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    ph.posthog.onFeatureFlags(function () {
      setIsReady(true);
    });
  }, []);

  const identifyForFeatures = useCallback((email: string) => {
    if (ph.isEnabled) {
      ph.posthog.setPersonPropertiesForFlags({ email });
    }
  }, []);

  const isFeatureEnabled = useCallback(
    (featureFlag: string) => {
      if (isReady) {
        const isEnabled = ph.posthog.isFeatureEnabled(featureFlag);
        return isEnabled;
      }
      return false;
    },
    [isReady]
  );

  return (
    <FeatureFlagContext.Provider
      value={{
        identifyForFeatures,
        isFeatureEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

function useFeatureFlags(): FeatureFlagContext {
  const context = React.useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider'
    );
  }
  return context;
}

export { FeatureFlagProvider, useFeatureFlags };
