const typography = {
  fonts: {
    body: 'IBM Plex Sans, sans-serif',
    heading: 'inherit',
  },

  fontSizes: {
    xs: '9px',
    sm: '12px',
    base: '16px',
    md: '18px',
    lg: '25px',
    xl: '30px',
    xxl: '39.0625px',
    xxxl: '50px',
  },

  lineHeights: {
    xs: '1.25',
    sm: '1.25',
    base: '1.25',
    md: '1.2',
    lg: '1.1',
    xl: '1',
    xxl: '1',
  },
};

export type Typography = typeof typography;

export default typography;
