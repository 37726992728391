import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  // define the part you're going to style
  container: {
    padding: 'md',
    borderRadius: 'sm',
    bg: mode('white', 'racingGreen.800')(props),
  },
  // header: {
  //   paddingBottom: '2px',
  // },
  body: {
    padding: '0',
  },
  // footer: {
  //   paddingTop: '2px',
  // },
}));

const regular = definePartsStyle((props) => ({
  ...props,
  container: {
    ...theme.components.Card.variants.elevated.container,
  },
}));

export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { regular },
});
