import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  tablist: {
    color: 'white',
    bg: mode('#3D4543', 'racingGreen.700')(props),
    borderTopRadius: 'sm',
  },
  tab: {
    color: 'white',
    fontWeight: 'bold',
    _selected: {
      color: mode('brand.primary', 'white')(props),
    },
    _hover: {
      bg: 'none',
    },
  },
  tabpanel: {
    bg: mode('#FFFFFF', 'racingGreen.800')(props),
    color: mode('#000000', '#FFFFFF')(props),
    borderBottomRadius: 'sm',
  },
  indicator: {
    color: 'green',
    borderColor: 'green',
  },
}));

const sizes = {
  base: definePartsStyle({
    tab: {
      fontSize: 'base',
      px: 'md',
      py: 'md',
      ml: 'sm',
    },
    tabpanel: {
      px: 'md',
      py: 'md',
    },
  }),
};

const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
});

export default tabsTheme;
