import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  thead: {
    th: {
      fontSize: 'xs',
      fontWeight: 'bold',
      py: 'xs',
      px: 'md',
      bg: mode('#3D4543', 'racingGreen.700')(props),
      borderColor: mode('brand.borderLight', 'brand.borderDark')(props),
      color: '#F2F2F2',
    },
    bg: '#3D4543',
    _dark: {
      th: {
        color: 'white',
      },
    },
  },
  tbody: {
    tr: {
      td: {
        px: 'md',
        py: 'md',
        fontSize: 'sm',
        borderColor: mode('brand.borderLight', 'brand.borderDark')(props),
      },
    },

    _dark: {
      tr: {
        td: {},
      },
    },
  },
}));

const tableTheme = defineMultiStyleConfig({
  baseStyle,
});

export default tableTheme;
