import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  // define the part you're going to style
  borderRadius: 'sm',
  py: 'xs',
  px: 'sm',

  fontWeight: 500,
});

const success = defineStyle({
  ...baseStyle,
});

const pending = defineStyle({
  ...baseStyle,
});

const badgeTheme = defineStyleConfig({
  baseStyle,
  variants: { success, pending },
});

export default badgeTheme;
