import { defineStyle, defineStyleConfig, theme } from '@chakra-ui/react';

const baseStyle = defineStyle({
  // define the part you're going to style
  borderRadius: 'md',
  width: { base: '100%', md: 'auto' },
  fontWeight: 500,
});

const icon = defineStyle((props) => ({
  ...theme.components.Button.variants.ghost(props),
  width: 'auto',
}));

const primary = defineStyle((props) => ({
  ...theme.components.Button.variants.solid(props),
  color: 'black',
  backgroundColor: 'primary.600',
}));

const secondary = defineStyle((props) => ({
  ...theme.components.Button.variants.solid(props),
  colorScheme: 'racingGreen',
  color: 'white',
  borderWidth: '1px',
}));

const outline = defineStyle((props) => ({
  ...theme.components.Button.variants.outline(props),
  color: props.colorMode === 'dark' ? 'white' : 'black',
  borderColor: 'primary.600',
}));

const buttonTheme = defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'primary',
    variant: 'primary',
  },
  variants: { icon, primary, secondary, outline },
});

export default buttonTheme;
