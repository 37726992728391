const size = {
  xs: '432px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1440px',
  icon: {
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '48px',
  },
};
export default {
  ...size,
};
